import type { App } from 'vue';
import type { CreateApp } from '@/createApp';

const APP_ROOT_ATTRIBUTE = 'data-app-root';
const APP_ROOT_SELECTOR = `[${APP_ROOT_ATTRIBUTE}]`;

const body = document.querySelector('body');

export default function createAppRegistry(createApp: CreateApp) {
	const appRoots: Map<Element, App<Element>> = new Map();

	Array.from(body?.querySelectorAll(APP_ROOT_SELECTOR) ?? [])
		.filter(filterNode)
		.forEach(mountAppRoot);

	document.dispatchEvent(new Event('app-roots-loaded'));

	function filterNode(el: Element) {
		return !el.parentElement?.closest(APP_ROOT_SELECTOR);
	}

	function mountAppRoot(el: Element) {
		// NOTE: Checks 'el' is not already registered.
		if (appRoots.has(el)) {
			return false;
		}

		const app = createApp();
		app.mount(el);

		appRoots.set(el, app);

		return true;
	}

	function destroy() {
		appRoots.forEach((appRoot) => {
			appRoot.unmount();
		});

		appRoots.clear();
	}

	return destroy;
}
