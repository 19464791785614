import { createApp as createVueApp, defineAsyncComponent } from "vue";

export type CreateApp = typeof createApp;

// Components
import HelloWorld from "@components/HelloWorld.vue";
import AppHeader from "@components/AppHeader.vue";
import AppNavigation from "@components/AppNavigation.vue";
import VideoComponent from "@components/VideoComponent.vue";
import CarouselComponent from "@components/CarouselComponent.vue";
import VueVimeoPlayer from "vue-vimeo-player";

// Async Components
const AsyncHelloWorld = defineAsyncComponent(() => {
  return import(
    /* webpackChunkName: "async-hello-world" */ "@components/AsyncHelloWorld.vue"
  );
});

export default function createApp(options: Record<string, unknown> = {}) {
  const app = createVueApp(options);

  app.use(VueVimeoPlayer);

  // Components
  app.component(AppHeader.name, AppHeader);
  app.component(AppNavigation.name, AppNavigation);
  app.component(HelloWorld.name, HelloWorld);
  app.component("AsyncHelloWorld", AsyncHelloWorld);
  app.component(VideoComponent.name, VideoComponent);
  app.component(CarouselComponent.name, CarouselComponent);

  return app;
}
